import React, { useEffect, useState } from "react";
import Header from "../DashboardComponents/Header";
import Sidebar from "../DashboardComponents/Sidebar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseurl } from "../Contants/Constanturls";
import { Button, Modal } from "react-bootstrap";

function Products() {

  let navigate = useNavigate();

  const [productmodal, setproductmodal] = useState(false);
  const [imagemodal, setimagemodal] = useState(false);

  const [product, setproduct] = useState([]);
  const [productpg, setproductpg] = useState([]);
  const [productcategory, setproductcategory] = useState([]);

  const [prevpgcount, setprevpgcount] = useState(0)
  const [pgcount, setpgcount] = useState(0)


  const [alldata, setalldata] = useState({
    category: "",
    brand: "",
    title: "",
    code: "",
    purchaseprice: "",
    price: "",
    offerprice: "",
    delcharge: "",
    size: "",
    quantity: "",
    colour: "",
    fakeordersold: "",
    rank: "",
    description: "",
    status: "",
    id: "",
  });

  const [productimage, setproductimage] = useState("");
  const [prmoreimage, setprmoreimage] = useState([]);
  const [getmoreimage, setgetmoreimage] = useState([]);
  const [moreimageproductid, setmoreimageproductid] = useState("");

  const [searchprname, setsearchprname] = useState("")
  const [searchprstatus, setsearchprstatus] = useState("")
  const [searchprcetgory, setsearchprcategory] = useState("")

  useEffect(() => {
    Productdata();
    ProductCategory();
  }, []);

  const Datas = (id) => {
    if (id == null) {
      setalldata({
        category: "",
        brand: "",
        title: "",
        code: "",
        purchaseprice: "",
        price: "",
        offerprice: "",
        delcharge: "",
        size: "",
        quantity: "",
        colour: "",
        fakeordersold: "",
        rank: "",
        description: "",
        status: "",
        id: "",
      });
      setproductmodal(true);
    } else {
      axios
        .get(`${baseurl}/ProductApp/product/`, {
          params: {
            id: id,
          },
        })
        .then((res) => {
          console.log(res.data.results[0]);
          var data = res.data.results[0];
          setproductmodal(true);
          setalldata({
            category: data.category,
            brand: data.brand,
            title: data.title,
            code: data.code,
            purchaseprice: data.purchase_price,
            price: data.price,
            offerprice: data.offer_price,
            delcharge: data.deliverycharge,
            size: data.size,
            quantity: data.quantity,
            colour: data.colour,
            fakeordersold: data.fake_order_sold,
            rank: data.rank,
            description: data.description,
            status: data.status,
            id: data.id,
          });
        });
    }
  };

  const ProductCategory = () => {
    axios.get(`${baseurl}/ProductApp/categoryall/`).then((res) => {
      setproductcategory(res.data);
      console.log(res.data);
    });
  };

  const Productdata = () => {
    if (!localStorage.getItem("usertoken")) {
      return navigate("/");
    } else {
      axios.get(`${baseurl}/ProductApp/product/`,{ 
        params : {
          name : searchprname,
          status : searchprstatus,
          category : searchprcetgory,

        }
      }  )
      .then((res) => {
        setproduct(res.data.results);
        setproductpg(res.data);
        if (prevpgcount == 0) {
          setprevpgcount(res.data.results.length)
        }
      });
    }
  };

  const handleChange = (e, index) => {
    const updatedAreas = [...prmoreimage];
    updatedAreas[index] = e.target.files[0];
    setprmoreimage(updatedAreas);
  };

  const handleRemove = (e, index) => {
    e.preventDefault();
    const newArray = [...prmoreimage];
    newArray.splice(index, 1);
    setprmoreimage(newArray);
  };

  const Addcountry = (e) => {
    e.preventDefault();
    setprmoreimage([...prmoreimage, ""]);
  };

  const changeHandler = (e) => {
    setalldata({ ...alldata, [e.target.name]: e.target.value });
  };

  const ProductSubmit = (e) => {
    e.preventDefault();
    if (alldata.category == "") {
      alert("Please Fill Category");
    } else if (alldata.brand == "") {
      alert("Please Fill Brand");
    } else if (alldata.title == "") {
      alert("Please Fill Title");
    } else if (alldata.code == "") {
      alert("Please Fill Code");
    } else if (alldata.price == "") {
      alert("Please Fill Price");
    } else if (alldata.rank == "") {
      alert("Please Fill Rank");
    } else if (alldata.description == "") {
      alert("Please Fill Description");
    } else if (alldata.status == "") {
      alert("Please Fill Status");
    } else if (productimage == "") {
      alert("Please Fill Image");
    } else {
      let form_data = new FormData();
      form_data.append("category", alldata.category);
      form_data.append("brand", alldata.brand);
      form_data.append("title", alldata.title);
      form_data.append("code", alldata.code);
      form_data.append("purchase_price", alldata.purchaseprice);
      form_data.append("price", alldata.price);
      form_data.append("offer_price", alldata.offerprice);
      form_data.append("deliverycharge", alldata.delcharge);
      form_data.append("size", alldata.size);
      form_data.append('colour',alldata.colour);
      form_data.append("quantity", alldata.quantity);
      form_data.append("fake_order_sold", alldata.fakeordersold);
      form_data.append("rank", alldata.rank);
      form_data.append("description", alldata.description);
      form_data.append("status", alldata.status);
      form_data.append("product_image", productimage);
      form_data.append("id", alldata.id);

      prmoreimage.forEach((element) => {
        form_data.append("images", element);
      });

      axios
        .post(`${baseurl}/ProductApp/product/`, form_data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res.data);
          setproductmodal(false);
          setalldata("");
          Productdata();
          ProductCategory();
          setprmoreimage([]);
          setproductimage("")
        });
      }
  };

  const Moreimage = (id) => {
    setimagemodal(true);
    setmoreimageproductid(id);
    axios
      .get(`${baseurl}/ProductApp/moreimages/`, {
        params: {
          id: id,
        },
      })
      .then((res) => {
        setgetmoreimage(res.data.Message);
      });
  };

  const DeleteMoreimage = (e) => {
    e.preventDefault();
    axios
      .delete(`${baseurl}/ProductApp/moreimages/`, {
        params: {
          id: e.target.value,
        },
      })
      .then((res) => {
        console.log(res.data);
        Productdata();
        ProductCategory();
        setimagemodal(false);
      });
  };

  const AddMoreImage = () => {
    let form_data = new FormData();
    prmoreimage.forEach((element) => {
      form_data.append("images", element);
    });
    form_data.append("productid", moreimageproductid);

    axios
      .post(`${baseurl}/ProductApp/moreimages/`, form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        setmoreimageproductid("");
        setimagemodal(false);
        Productdata();
        ProductCategory();
        setprmoreimage([]);
      });
  };

  const DeleteProduct = (e) => {
    e.preventDefault();
    axios
      .delete(`${baseurl}/ProductApp/product/`, {
        params: {
          id: e.target.value,
        },
      })
      .then((res) => {
        Productdata();
        ProductCategory();
      });
  };
  const ChangeStatus = (e) => {
    var data = {
      "id" : e.target.value
    }
    axios.post(`${baseurl}/ProductApp/statuschange/`,data)
    .then((res)=>{
      Productdata();
      ProductCategory();
    })
  }

  const paginationPrev = () => {
    axios.get(`${productpg.previous}`)
    .then((res)=>{
      setproduct(res.data.results);
      setproductpg(res.data);
      setpgcount(Number(pgcount) - Number(prevpgcount))
     
  })
  }

  const paginationNext = () => {
    axios.get(`${productpg.next}`)
    .then((res)=>{
      setproduct(res.data.results);
      setproductpg(res.data);
      setpgcount(Number(pgcount) + Number(prevpgcount))
  })
  }
  return (
    <div>
      <Header />
      <Sidebar />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-header">
                <h3 className="card-title">Search</h3>
                <div className="card-tools"></div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        className="form-control select2"
                        placeholder="Product Name"
                        style={{ width: "100%" }}
                        value={searchprname}
                        onChange ={(e)=>setsearchprname(e.target.value)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label>Status</label>
                      <input
                        className="form-control select2"
                        placeholder="Product Status"
                        style={{ width: "100%" }}
                        value={searchprstatus}
                        onChange ={(e)=>setsearchprstatus(e.target.value)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label>Category</label>
                      <input
                        className="form-control select2"
                        placeholder="Product Category"
                        style={{ width: "100%" }}
                        value={searchprcetgory}
                        onChange ={(e)=>setsearchprcategory(e.target.value)}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-6">
                        <button
                          type="button"
                          style={{ float: "right", width: "100%" }}
                          class="btn btn-primary btn-block"
                          onClick={Productdata}
                        >
                          Search
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button
                          type="button"
                          style={{ float: "right", width: "100%" }}
                          class="btn btn-primary btn-block"
                          onClick={()=>{Productdata();setsearchprname("");setsearchprstatus("");setsearchprcategory("")}}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.card-body */}
            </div>

            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Product List</h1>
              </div>

              <div className="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <button
                    type="button"
                    class="btn btn-primary btn-block"
                    onClick={() => Datas(null)}
                  >
                    <i class="fa fa-plus"></i> Add Product
                  </button>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Bordered Table</h3>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th style={{ width: 20 }}>#</th>
                          <th style={{ width: 130 }}>Product Title</th>
                          <th style={{ width: 90 }}>images</th>
                          <th style={{ width: 280 }}>Product Details</th>
                          <th style={{ width: 40 }}>Created date</th>
                          <th style={{ width: 30 }}>Status</th>
                          <th style={{ width: 30 }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {product.count === 0 ? (
                          "No Product Found"
                        ) : (
                          <>
                            {product.map((value, key) => (
                              <tr key={key}>
                                <td>{productpg.previous == null ? `${key+1}` : `${pgcount + key + 1}`}</td>
                                <td>
                                  {" "}
                                  <b>Title :</b> {value.title}
                                  <br></br>
                                  <img
                                    src={`${value.product_image}`}
                                    style={{
                                      width: "80px",
                                      objectFit: "contain",
                                    }}
                                  />
                                </td>
                                <td>
                                  {value.productimage.map((value, key) => (
                                    <img
                                      src={`${baseurl}${value.productimage}`}
                                      style={{ height: "50px" }}
                                      className="mr-1"
                                    />
                                  ))}

                                  <button
                                    className="badge bg-warning mt-4"
                                    style={{ border: "None", marginLeft: 30 }}
                                    onClick={() => Moreimage(value.id)}
                                  >
                                    View/Edit
                                  </button>
                                </td>
                                <td>
                                  <b>Description : </b>
                                  {value.description} <br />
                                  <b>Brand : </b>
                                  {value.brand} <br />
                                  <b>Category : </b>
                                  {value.category} <br />
                                  <b>Code : </b>
                                  {value.code}
                                </td>
                                <td>
                                  <span>{value.created_date}</span>
                                </td>
                                <td>
                                  {value.status == "Enable" ? (
                                    <button
                                      className="badge bg-success"
                                      style={{"borderRadius":"5px","height": "22px","border":"None"}}
                                      value={value.id}
                                      onClick={(e)=>ChangeStatus(e)}
                                    >
                                      {value.status}
                                    </button>
                                  ) : (
                                    <button
                                    
                                      className="badge bg-danger"
                                      style={{"borderRadius":"5px","height": "22px","border":"None"}}
                                      value={value.id}
                                      onClick={(e)=>ChangeStatus(e)}
                                      
                                    >
                                      {value.status}
                                    </button>
                                  )}
                                  
                                </td>
                                <td>
                                  <button
                                    className="badge bg-warning mr-1"
                                    style={{ border: "None" }}
                                    onClick={() => Datas(value.id)}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    className="badge bg-danger"
                                    style={{ border: "None" }}
                                    value={value.id}
                                    onClick={(e) => DeleteProduct(e)}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* /.card-body */}
                  <div className="card-footer clearfix">
                    <ul className="pagination pagination-sm m-0 float-right">
                      <li className="page-item" onClick={paginationPrev}>
                        <p className="page-link" style={ productpg.previous == null ? {cursor : "not-allowed"} : {cursor : "pointer"}} >
                          Previous
                        </p>
                      </li>
                    
                      <li className="page-item" onClick={paginationNext}>
                        <p className="page-link" style={ productpg.next == null ? {cursor : "not-allowed"} : {cursor : "pointer"}} >
                          Next
                        </p>
                      </li>
                    </ul>
                  </div>
                 
                </div>
              </div>
              {/* /.col */}
            </div>
          </div>
        </section>

        <Modal
          show={productmodal}
          // onHide={true}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Add/ Edit Product
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputState">Category*</label>
                  <select
                    className="form-control"
                    name="category"
                    onChange={changeHandler}
                  >
                    <option selected hidden>
                      Choose Category
                    </option>
                    {productcategory.map((value, key) => (
                      <option value={value.id} key={key}>
                        {value.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <label>Brand*</label>
                  <input
                    type="text"
                    value={alldata.brand}
                    name="brand"
                    onChange={changeHandler}
                    className="form-control"
                    placeholder="Brand"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Title*</label>
                  <input
                    type="text"
                    className="form-control"
                    value={alldata.title}
                    name="title"
                    onChange={changeHandler}
                    placeholder="Title"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Code*</label>
                  <input
                    type="text"
                    className="form-control"
                    value={alldata.code}
                    name="code"
                    onChange={changeHandler}
                    placeholder="Code"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4">
                  <label>Purchase Price</label>
                  <input
                    type="text"
                    className="form-control"
                    value={alldata.purchaseprice}
                    name="purchaseprice"
                    onChange={changeHandler}
                    placeholder="Purchase Price"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>Price*</label>
                  <input
                    type="text"
                    className="form-control"
                    value={alldata.price}
                    name="price"
                    onChange={changeHandler}
                    placeholder="Price"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>Offer Price</label>
                  <input
                    type="text"
                    className="form-control"
                    value={alldata.offerprice}
                    name="offerprice"
                    onChange={changeHandler}
                    placeholder="Offer Price"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4">
                  <label>Delivery Charge</label>
                  <input
                    type="text"
                    className="form-control"
                    value={alldata.delcharge}
                    name="delcharge"
                    onChange={changeHandler}
                    placeholder="Delivery Charge"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>Size</label>
                  <input
                    type="text"
                    className="form-control"
                    value={alldata.size}
                    name="size"
                    onChange={changeHandler}
                    placeholder="Size"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>Quantity</label>
                  <input
                    type="text"
                    className="form-control"
                    value={alldata.quantity}
                    name="quantity"
                    onChange={changeHandler}
                    placeholder="Quantity"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4">
                  <label>Colour</label>
                  <input
                    type="text"
                    className="form-control"
                    value={alldata.colour}
                    name="colour"
                    onChange={changeHandler}
                    placeholder="Colour"
                    
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>fake Order Sold</label>
                  <input
                    type="text"
                    className="form-control"
                    value={alldata.fakeordersold}
                    name="fakeordersold"
                    onChange={changeHandler}
                    placeholder="Fake Order Sold"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>Rank*</label>
                  <input
                    type="text"
                    className="form-control"
                    value={alldata.rank}
                    name="rank"
                    onChange={changeHandler}
                    placeholder="Rank"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Description*</label>
                  <textarea
                    type="text"
                    className="form-control"
                    value={alldata.description}
                    name="description"
                    onChange={changeHandler}
                    placeholder="Description"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Status*</label>
                  <select
                    className="form-control"
                    name="status"
                    onChange={changeHandler}
                  >
                    <option selected hidden>
                      {" "}
                      {alldata.status == ""
                        ? "Choose Category"
                        : alldata.status}
                    </option>
                    <option value="Enable">Enable</option>
                    <option value="Disable">Disable</option>
                  </select>
                </div>
              </div>

              <div className="form-group">
                <label>Product Image*</label>
                <br></br>
                {productimage == "" ? (
                  ""
                ) : (
                  <img
                    src={`${productimage}`}
                    height="100px"
                    width="80px"
                    style={{ objectFit: "contain" }}
                  />
                )}
                <input
                  type="file"
                  onChange={(e) => setproductimage(e.target.files[0])}
                ></input>
              </div>

              <hr />

              {alldata.id != "" ? (
                ""
              ) : (
                <>
                  {" "}
                  <label className="mt-2">Add Multiple Images:</label>
                  <br />
                  <Button variant="primary" onClick={(e) => Addcountry(e)}>
                    Add Photo
                  </Button>
                </>
              )}

              {prmoreimage.map((country, index) => {
                return (
                  <>
                    <div key={index}>
                      <input
                        type="file"
                        onChange={(e) => handleChange(e, index)}
                        className="mt-4"
                      />
                      <Button
                        className="btn btn-danger btn-sm"
                        onClick={(e) => handleRemove(e, index)}
                      >
                        Remove
                      </Button>
                    </div>
                  </>
                );
              })}
            </form>

            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setproductmodal(false);
                  setprmoreimage([]);
                }}
              >
                Cancel
              </Button>
      
              <Button variant="primary" onClick={(e) => ProductSubmit(e)}>
                Save
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>

        <Modal
          show={imagemodal}
          // onHide={true}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Add/ Edit Product
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              {getmoreimage.length == 0 ? (
                "No Images"
              ) : (
                <>
                  {getmoreimage.map((value, key) => (
                    <>
                      <img
                        src={`${baseurl}${value.productimage}`}
                        style={{
                          width: "250px",
                          height: "150px",
                          objectFit: "contain",
                        }}
                      />
                      <button
                        className="btn btn-sm bg-danger"
                        value={value.id}
                        onClick={(e) => DeleteMoreimage(e)}
                      >
                        {" "}
                        Remove Photo{" "}
                      </button>
                    </>
                  ))}
                </>
              )}

              <hr />

              <Button variant="primary" onClick={(e) => Addcountry(e)}>
                Add Photo
              </Button>

              {prmoreimage.map((country, index) => {
                return (
                  <>
                    <div key={index}>
                      <input
                        type="file"
                        onChange={(e) => handleChange(e, index)}
                        className="mt-4"
                      />
                      <Button
                        className="btn btn-danger btn-sm"
                        onClick={(e) => handleRemove(e, index)}
                      >
                        Remove
                      </Button>
                    </div>
                  </>
                );
              })}
            </form>

            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setimagemodal(false);
                  setprmoreimage([]);
                }}
              >
                Cancel
              </Button>

              <Button variant="primary" onClick={() => AddMoreImage()}>
                Save
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default Products;
