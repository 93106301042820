import React, { useEffect, useState } from 'react'
import Header from '../DashboardComponents/Header'
import Sidebar from '../DashboardComponents/Sidebar'
import { Link, useNavigate } from "react-router-dom";
import { baseurl } from '../Contants/Constanturls';
import axios from 'axios';
import { Button, Modal } from "react-bootstrap";
import { CSVLink } from 'react-csv'




function OrderPage() {
    let navigate = useNavigate();
    
    const [allorderpg, setallorderpg] = useState([])
    const [allorders, setallorders] = useState([])

    const [allstatus, setallstatus] = useState([])

    const [arrays, setarrays] = useState([]);

    const [orderwithphone, setorderwithphone] = useState([])
    const [orderwithphonemodal, setorderwithphonemodal] = useState(false)

    const [report, setreport] = useState([])

    const [allphone, setallphone] = useState("")
    const [alluserrandomid, setalluserrandomid] = useState("")


    const [prevpgcount, setprevpgcount] = useState(0)
    const [pgcount, setpgcount] = useState(0)

    const [orderclient, setorderclient] = useState("")
    const [orderphone, setorderphone] = useState("")
    const [ordercity, setordercity] = useState("")
    const [orderid, setorderid] = useState("")
    const [orderupdatemodal, setorderupdatemodal] = useState(false)
    const [allcities, setallcities] = useState([])

    const [searchname, setsearchname] = useState("")


    useEffect(() => {
        Allorder(null)
        AllStatus()
        Allcity()
    }, [])

    const headers  = [
      { label : 'No', key : 'orderid'},
      { label : 'Client Name', key : 'clientname'},
      { label : 'Address', key : 'address'},
      { label : 'Contact Number', key : 'phone'},
      { label : 'City', key : 'city'},
      { label : 'Product Code', key : 'product_code'},
      { label : 'Product Name', key : 'product_name'},
      { label : 'Delivery Charge', key : 'deliverycharge'},
      { label : 'Product Price', key : 'product_price'},
      { label : 'Quantity', key : 'quantity'},
      { label : 'Product VAT', key : 'product_VAT'},
      { label : 'Product Total Price', key : 'product_total'},
      { label : 'Size', key : 'product_size'},
      { label : 'Colour', key : 'product_colour'},
    ];

    
    const csvReport = {
      filename : 'Report.csv',
      headers : headers,
      data : report,
      
    }

    const Allcity = () => {
      axios.get(`${baseurl}/StatusApp/cities/`)
      .then((res)=>{
           setallcities(res.data)
      })
    }
   

    const AllStatus = () => {
      axios.get(`${baseurl}/StatusApp/status/`)
      .then((res)=>{
          setallstatus(res.data)
      })
    }

    const Allorder = (data) =>{
        if (!localStorage.getItem("usertoken")) {
            return navigate("/login");
          } else {
            
            if (data == null){
                  axios
                    .get(`${baseurl}/OrderApp/allorder/`)
                    .then((res) => {
                      setallorderpg(res.data)
                      setallorders(res.data.results)
                      if (prevpgcount == 0) {
                        setprevpgcount(res.data.results.length)
                      }
                      
                 
                     
                      axios.get(`${baseurl}/OrderApp/multipleorders/`)
                      .then((res)=>{
                          var x = []
                          var userids = []
                          var y =[]
                        
                          res.data.forEach(element => {
                              x.push(element.phone)
                              userids.push(element.userrandomid)
                          });
                          
                          var useridduplicates = userids.filter((value,index)=>userids.indexOf(value) !== index)
                          var y  = x.filter((value,index)=>x.indexOf(value) !== index)

                          setallphone(y)
                       
                          setalluserrandomid(useridduplicates)

                          
                          
                      })
                      
                    });
                  }
            else{
              axios
              .get(`${baseurl}/OrderApp/allorder/`,{
                params : {
                  "statuscode" : data
                }
              })
              .then((res)=>{
                setallorderpg(res.data)
                setallorders(res.data.results)
                
              })
            }
          }
    }

    const StatusChange = (id,statuscode) => {
      var data = {
        "id" : id,
        "statuscode" : statuscode
      }
      axios.post(`${baseurl}/OrderApp/statuschange/`,data)
      .then((res)=>{
          Allorder()
      })
    }

    const handleCheckboxChange = (e) => {
  
      let newArray = [...arrays, e.target.id];
      if (arrays.includes(e.target.id)) {
        newArray = newArray.filter(id => id !== e.target.id);
      } 
      setarrays(newArray)
      CSVGenerator(newArray)
     
    };


    const ChangestatusMultiply = (statuscode) => {
      var data = {
        "id" : arrays,
        "statuscode" : statuscode
      }
      axios.post(`${baseurl}/OrderApp/statuschangemultiply/`,data)
      .then((res)=>{
        Allorder()
        setarrays([])
        window.location.reload()
      })
    }

  const Orderwithphone = (phone) => {
    axios
    .get(`${baseurl}/OrderApp/orderwithphone/`,{
      params : {
          'phone' : phone
      }
    })
    .then((res)=>{
      setorderwithphone(res.data)
      setorderwithphonemodal(true)
    })
  }

  const CSVGenerator = (datas) => {
    console.log("qwertyuiop",datas)
    var data= {
      "id" : datas
    }
    axios.post(`${baseurl}/OrderApp/CsvReportView/`,data)
    .then((res)=>{
      setreport(res.data.data)  
      
    })
  }

  const OrderDate = (id) => {
    axios
    .get(`${baseurl}/OrderApp/allorder/`,{
      params : {
        "id" : id
      }
    })
    .then((res)=>{
      setorderclient(res.data.results[0].clientname)
      setorderphone(res.data.results[0].phone)
      setordercity(res.data.results[0].city)
      setorderid(res.data.results[0].id)
      setorderupdatemodal(true)
    })
  }

  const paginationPrev = () => {
    axios.get(`${allorderpg.previous}`)
    .then((res)=>{
      setallorders(res.data.results);
      setallorderpg(res.data);
      setpgcount(Number(pgcount) - Number(prevpgcount))
     
  })
  }

  const paginationNext = () => {
    axios.get(`${allorderpg.next}`)
    .then((res)=>{
      setallorders(res.data.results);
      setallorderpg(res.data);
      setpgcount(Number(pgcount) + Number(prevpgcount))
     
  })
 
  }

  const UpdateOrder = () => {
    var data = {
      'clientname': orderclient,
      'phone': orderphone,
      'city' : ordercity,
      'id' : orderid
    }
    axios
    .post(`${baseurl}/OrderApp/allorder/`,data)
    .then((res)=>{
      console.log(res.data)
      Allorder(null)
      setorderupdatemodal(false)
    })
  }


  const SearchOrder = () => {
    axios
    .get(`${baseurl}/OrderApp/allorder/`,{
      params : {
        'productname' : searchname
      }
    })
    .then((res)=>{
     
      setallorderpg(res.data)
      setallorders(res.data.results)
    })
  }
 
  return (
    <div>
        <Header />
        <Sidebar />
        <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-header">
                <h3 className="card-title">Search</h3>
                <div className="card-tools"></div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        className="form-control select2"
                        value={searchname}
                        onChange={(e)=>setsearchname(e.target.value)}
                        placeholder="Product Name"
                        style={{ width: "70%" }}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8">
                    <div className='row'>
                      <p > Filter By : &nbsp;</p>
                      <button style={{"backgroundColor":'black',"color":"white" , "border" :"None","borderRadius":"5px","marginRight":"2px"}} onClick={(e)=>Allorder(null)}>All Order</button>
                      {allstatus.map((value,key)=>(
                           <button value={value.statuscode} style={{"backgroundColor":`${value.colour}` , "border" :"None","borderRadius":"5px","marginRight":"2px"}} onClick={(e)=>Allorder(e.target.value)}>{value.name}</button>
                      ))}
                     
                    </div>
                  </div>
                  
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-6">
                        <button
                          type="button"
                          style={{ float: "right", width: "100%" }}
                          onClick = {SearchOrder}
                          class="btn btn-primary btn-block"
                        >
                          Search
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button
                          type="button"
                          style={{ float: "right", width: "100%" }}
                          onClick={()=>{Allorder(null);setsearchname("")}}
                          class="btn btn-primary btn-block"
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}

            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">order List</h1>
              </div>

              <div className="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <button
                    type="button"
                    class="btn btn-primary btn-block"    
                  >
                    <i class="fa fa-plus"></i> Add Order
                  </button>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className='row'>
                      <div className='col-md-3'>
                        <select className='form-control' onChange={(e)=>ChangestatusMultiply(e.target.value)}>
                          <option hidden defaultChecked>-- Change Status -- </option>
                          {allstatus.map((value,key)=>(
                              <option value={value.statuscode}> { value.name} </option>
                          ))}
            
                      </select>
                      </div>
                      <div className='col-md-3'>
                        <button className='btn' style={{"backgroundColor":"#bf2222"}} onClick={()=>window.location.reload()}><i className='fas fa-download'></i> <CSVLink {...csvReport} >Report</CSVLink> </button>
                      </div>
                    </div>
                  
                  </div>

                  <div className="card-body">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th style={{ width: 20 }}>#</th>
                          <th style={{ width: 80 }}>No</th>
                          <th style={{ width: 250 }}>Product {prevpgcount}</th>
                          <th style={{ width: 100 }}>Client Name</th>
                          <th style={{ width: 40 }}>Phone</th> 
                          <th style={{ width: 40 }}>City</th> 
                          <th style={{ width: 40 }}>Status</th> 
                          <th style={{ width: 40 }}>Action</th> 
                        </tr>
                      </thead>
                      <tbody>
                          {allorders.length == 0 ? 'No Data Found' : 
                          <>

                            {allorders.map((value,key)=>(
                              <>
                                {/* <tr style={ allphone.includes(value.phone) == true ? { backgroundColor:'lightgreen'} : alluserrandomid.includes(value.userrandomid) == true ? {backgroundColor : 'lightgrey'} : {}} > */}

                                <tr style={ allphone.includes(value.phone) == true ? { backgroundColor:'lightgray'} : alluserrandomid.includes(value.userrandomid) == true ? {backgroundColor : 'lightgreen'} : {}} >
                                <td>{allorderpg.previous == null ? `${key+1}` : `${pgcount + key + 1}`}<input type="checkbox"  id={value.id}
                              onChange={(e) => handleCheckboxChange(e)}></input></td> 
                                  <td><Link to={`/shop/${value.orderid}/`} style={{"color":"black","cursor":"pointer"}}>{value.orderid}</Link></td>
                                  <td>{value.product_name}<br></br>
                                  <img src={`${value.product_image_url}`} alt height="100px" width="50px" style={{"objectFit":"contain"}}/></td> 
                                  <td>{value.clientname}</td> 
                                  <td style={{"cursor":"pointer"}} onClick={()=>Orderwithphone(value.phone)}>{value.phone}</td> 
                                  <td>{value.city}</td> 
                                  <td><button style={{"backgroundColor":`${value.status[0].colour}`,"border":"None","borderRadius":"5px"}}>{value.status[0].name}</button><br/>
                                    <select className='btn btn-mini mt-1 ' style={{"border" :"1px solid black"}} onChange={(e)=>StatusChange(value.id,e.target.value)}>
                                      <option defaultChecked hidden>Change </option>
                                      {allstatus.map((value,key)=>(

                                           <option value={value.statuscode}>{value.name}</option>

                                      ))}

                                    </select>
                                  </td> 
                                  <td>
                                  <button
                                    className="badge bg-warning mr-1"
                                    style={{ border: "None" }}
                                    onClick={()=>OrderDate(value.id)}
                                  >
                                    Edit
                                  </button>
                                    </td> 
                                </tr> 
                               
                              </>
                            ))}
                          </>
                          }
                        
                      </tbody>
                    </table>
                  </div>

                  <div className="card-footer clearfix">
                    <ul className="pagination pagination-sm m-0 float-right">
                      <li className="page-item" onClick={paginationPrev}>
                        <p className="page-link" style={ allorderpg.previous == null ? {cursor : "not-allowed"} : {cursor : "pointer"}}>
                          Previous
                        </p>
                      </li>
                    
                      <li className="page-item" onClick={paginationNext} >
                        <p className="page-link" style={ allorderpg.next == null ? {cursor : "not-allowed"} : {cursor : "pointer"}}>
                          Next
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          show={orderwithphonemodal}
          // onHide={true}
          backdrop="static"
          keyboard={false}
          size="lg" 
         
        >
          <Modal.Header>
            <Modal.Title>Orders</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">No</th>
                    <th scope="col">Product</th>
                    <th scope="col">Client</th>
                    <th scope="col">Phone</th>
                    <th scope="col">City</th>
                    <th scope="col">Status</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {orderwithphone.length == 0 ? 'No data Found' : 
                    <>
                    {orderwithphone.map((value,key)=>(

                       <tr key={key}>
                       <th scope="row">{key+1}</th>
                       <td>{value.orderid}</td>
                       <td>{value.product_name}<br></br>
                            <img src={`${value.product_image_url}`} alt height="100px" width="50px" style={{"objectFit":"contain"}}/></td> 
                       <td>{value.clientname}</td>
                       <td>{value.phone}</td>
                       <td>{value.city}</td>
                       <td><button style={{"backgroundColor":`${value.status[0].colour}`,"border":"None","borderRadius":"5px"}}>{value.status[0].name}</button></td>
                       <td>{value.created_date}</td>
                     </tr>

                    ))}
                    </>

                 }

                </tbody>
              </table>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>setorderwithphonemodal(false)}  >
                Cancel
              </Button>
      
            </Modal.Footer>
          </Modal.Body>
        </Modal>



        <Modal
          show={orderupdatemodal}
          // onHide={true}
          backdrop="static"
          keyboard={false}
         
         
        >
          <Modal.Header>
            <Modal.Title>Add/Edit Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className='row mb-2'>
                <div className='col-md-6'>
                  <label>Client Name</label>
                  <input type="text" name="clientname" value={orderclient} className='form-control' onChange={(e)=>setorderclient(e.target.value)} ></input>
                </div>
                <div className='col-md-6'>
                  <label>Client Phone</label>
                  <input type="number" name="clientname" value={orderphone} className='form-control' onChange={(e)=>setorderphone(e.target.value)} ></input>
                </div>
              </div>
              <div className='row  mb-4'>
              <div className='col-md-6'>
                  <label>Client City</label>
                  <select className='form-control' value={ordercity} onChange={(e)=>setordercity(e.target.value)}>
                    <option hidden defaultChecked> -- Choose City -- </option>
                    {allcities.length== 0 ? '' : 
                    <>
                    {allcities.map((value,key)=>(
                         <option value={value.name} > {value.name}</option>
                    ))}
                    </>
                    }
                  </select>
                </div>
                <div className='col-md-6'>
                  
                </div>
              </div>
            </form>
          
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>setorderupdatemodal(false)} >
                Cancel
              </Button>
              <Button variant="primary"  onClick={UpdateOrder} >
                Save
              </Button>
      
            </Modal.Footer>
          </Modal.Body>
        </Modal>



      </div>
    </div>
  )
}

export default OrderPage