import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Header from "../DashboardComponents/Header";
import Sidebar from "../DashboardComponents/Sidebar";
import { useParams } from 'react-router-dom'
import axios from "axios";
import { baseurl } from "../Contants/Constanturls";
import { Button, Modal } from "react-bootstrap";


function OrderDetailsShow() {
    const params = useParams()

    const [allorder, setallorder] = useState([])
    
    const [orderid, setorderid] = useState("")
    const [createddate, setcreateddate] = useState("")
    const [name, setname] = useState("")
    const [address, setaddress] = useState("")

    const [subtotal, setsubtotal] = useState("")
    const [shipppingfeee, setshipppingfeee] = useState("")
    const [totalVAT, settotalVAT] = useState("")


    const [changedsize, setchangedsize] = useState("")
    const [changedcolour, setchangedcolour] = useState("")
    const [changedprice, setchangedprice] = useState("")
    const [changedqty, setchangedqty] = useState("")
    const [changeddelcharge, setchangeddelcharge] = useState("")
    const [changedorderid, setchangedorderid] = useState("")
    // const [orderstatus, setorderstatus] = useState("")


    const [changingmodal, setchangingmodal] = useState(false)


    const componentRef = useRef();
    const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });
  

  useEffect(() => {
    OrderwithUser()
  }, [])

  const OrderwithUser = () => {
    axios.get(`${baseurl}/OrderApp/orderwithuser/`,{
      params:{
        orderid : params.id
      }
    })
    .then((res)=>{
     
      setallorder(res.data.alldata)
      setorderid(res.data.singledata.orderid)
      setcreateddate(res.data.singledata.created_date)
      setaddress(res.data.singledata.address)
      setname(res.data.singledata.clientname)

      var product_total = 0
      var vat_total = 0
      var deliverycharge = 0

      for (var i=0 ;i <res.data.alldata.length;i++){
        product_total = Number(product_total) + Number(res.data.alldata[i].product_price * res.data.alldata[i].quantity)
        vat_total = Number(vat_total) + Number(res.data.alldata[i].product_VAT)
        if (res.data.alldata[i].deliverycharge > deliverycharge ) {
          deliverycharge = res.data.alldata[i].deliverycharge
        }
      }

        setsubtotal(product_total)
        settotalVAT(vat_total)
        setshipppingfeee(deliverycharge)
     
    })
  }




  const Orderwithid = (id) => {
    axios.get(`${baseurl}/OrderApp/allorder/`,{
      params:{
        id : id
      }
    })
    .then((res)=>{
        setchangingmodal(true)
        setchangedcolour(res.data.results[0].product_colour)
        setchangedsize(res.data.results[0].product_size)
        setchangedorderid(res.data.results[0].id)
        setchangedprice(res.data.results[0].product_price)
        setchangedqty(res.data.results[0].quantity)
        setchangeddelcharge(res.data.results[0].deliverycharge)
    })
  }

  const OrderUpdate = (e) => {
    var data= {
      'deliverycharge' : changeddelcharge,
      'id' : changedorderid,
      'quantity' : changedqty,
      'product_price' : changedprice,
      'product_size' : changedsize,
      'product_colour' : changedcolour,
      // 'details' : orderstatus
    }
    axios.post(`${baseurl}/OrderApp/allorder/`,data)
    .then((res)=>{
      OrderwithUser()
      setchangingmodal(false)
    })

  }

  const Deleteorder = (id) => {
    const data = {
      id: id,
    };
    axios
      .delete(`${baseurl}/OrderApp/allorder/`,{
        params:{
          id:id
        }
      })
      .then((res) => {
          OrderwithUser()
      });
  };

  
  return (
    <div>
        <Header/>
        <Sidebar/>
        <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
         

            <div className="row mb-2">
              <div className="col-sm-6">
               
              </div>

              <div className="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <button
                    type="button"
                    className="btn btn-primary btn-block"    
                  >
                    <i className="fa fa-arrow-left"></i> Back
                  </button>
                </ol>
                <ol class="breadcrumb float-sm-right">
                  <button
                    type="button"
                    className="btn btn-primary btn-block mr-2 "    
                    onClick={handlePrint}
                  >
                    <i className="fa fa-download"></i> Print
                  </button>
                </ol>

         
              </div>
            </div>
          </div>
        </div>

        <section className="content"  ref={componentRef} >
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className='row'>
                      <div className='col-md-3'>
                            <h4>Orders</h4>
                      </div>
                   
                    </div>
                  
                  </div>

                  <div className="card-body">
                  <div>
                    <label>Order No / Date : </label>{"   "}{orderid} / {createddate} <br/>
                    <label>Name : </label>{"   "}{name} <br />
                    <label>Address : </label>{"   "}{address}
                    
                    </div>
                    <table className="table table-bordered">
                      <thead>
                        
                        <tr>
                          <th >#</th>
                          <th >Product</th>
                          <th >Size</th>
                          <th >Colour</th>
                          <th >U- Price</th> 
                          <th >Qty</th> 
                          <th >Sub Total</th> 
                          <th >VAT</th> 
                          <th >Delivery</th> 
                          <th >Total</th> 
                          <th >Action</th> 
                        </tr>
                      </thead>
                      <tbody>
                        {allorder.length == 0 ? '' : 
                        <>
                        {allorder.map((value,key)=>(

                        <tr key={key}>
                            <td>{key+1}</td>
                            <td>{value.product_name}<br></br>
                                <img src={`${value.product_image_url}`} alt height="100px" width="50px" style={{"objectFit":"contain"}}/></td>
                            {/* <td>{value.size}</td> */}
                            <td>{value.product_size}</td>
                            <td>{value.product_colour}</td>
                            <td>{value.product_price}</td>
                            <td>{value.quantity}</td>
                            <td>{value.product_price * value.quantity}</td>
                            <td>{value.product_VAT}</td>
                            <td>{value.deliverycharge}</td>
                            <td>{Number(value.product_price) * Number(value.quantity) + Number(value.product_VAT) + Number(value.deliverycharge)}</td>
                            <td> <button
                                    className="badge bg-warning mr-1"
                                    style={{ border: "None" }}
                                    onClick={()=>Orderwithid(value.id)}
                                  
                                  >
                                    Edit
                                  </button>
                                  <button
                                    className="badge bg-danger"
                                    onClick={() => {
                                      window.confirm(
                                        "Do You Want To delete   " + value.product_name
                                      )
                                        ? Deleteorder(value.id)
                                        : window.close();
                                    }}
                                    style={{ border: "None" }}
                                  >
                                    Delete
                                  </button>
                                </td>
                        </tr>

                        ))}
                         </>}
                      </tbody>
                      <tfoot>
                        <tr>
                          {/* <td colspan="7">Delivery Note : </td> 
                          <td colspan="4">1234567</td>  */}
                        </tr>
                        <tr>
                          <td colspan="7">Sub Total :</td> 
                          <td colspan="4">{subtotal}</td> 
                        </tr>
                        <tr>
                          <td colspan="7">Shipping Fee :</td> 
                          <td colspan="4">{shipppingfeee}</td> 
                        </tr>
                        <tr>
                          <td colspan="7">VAT :</td> 
                          <td colspan="4">{totalVAT}</td> 
                        </tr>
                        <tr>
                          <td colspan="7">Grand Total : </td> 
                          <td colspan="4">{Number(subtotal) + Number(shipppingfeee) + Number(totalVAT)}</td> 
                        </tr>
                      </tfoot>
                    </table>
                  </div>

              
                </div>
              </div>
            </div>
          </div>
        </section>
        

        <Modal
          show={changingmodal}
          // onHide={true}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Add/ Edit Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form autoComplete="off">
              <div className="row">
                <div className="col-md-3">
                      <label>Size</label>
                      <input type="text" className="form-control" value={changedsize} onChange={(e)=>setchangedsize(e.target.value)} ></input>
                </div>
                <div className="col-md-3">
                      <label>Colour</label>
                      <input type="text" className="form-control" value={changedcolour} onChange={(e)=>setchangedcolour(e.target.value)}></input>
                </div>
                <div className="col-md-3">
                      <label>Unit Price</label>
                      <input type="text" className="form-control" value={changedprice} onChange={(e)=>setchangedprice(e.target.value)}></input>
                </div>
                <div className="col-md-3">
                      <label>Quantity</label>
                      <input type="number" className="form-control" value={changedqty} onChange={(e)=>setchangedqty(e.target.value)}></input>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 mb-2">
                      <label>DeliveryCharge</label>
                      <input type="number" className="form-control" value={changeddelcharge} onChange={(e)=>setchangeddelcharge(e.target.value)}></input>
                </div>
                {/* <div className="col-md-9 mb-2">
                      <label>order Status</label>
                      <textarea  className="form-control" value={orderstatus} onChange={(e)=>setorderstatus(e.target.value)}></textarea>
                </div> */}
                
              </div>
            </form>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>setchangingmodal(false)} >
                Cancel
              </Button>
              {/* <Button variant="primary" onClick={()=>{addcategory();handleClose()}}> */}
              <Button variant="primary" onClick={OrderUpdate} >
                Save
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>



      </div>
    </div>
  )
}

export default OrderDetailsShow