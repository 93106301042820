import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseurl } from '../Contants/Constanturls'
import { useNavigate } from "react-router-dom";

function Loginpage() {
  let navigate = useNavigate();

  const [username, setusername] = useState("")
  const [password, setpassword] = useState("")
  const [error,seterror] = useState("")
  

  useEffect(() => {
   if (localStorage.getItem('usertoken')){
     return navigate('/')
   }
      
  }, [])
  

  const Login = (e) => {
    e.preventDefault();
    if (username == ''){
      alert("Please Fill Username")
    }
    else if (password == ''){
      alert("Please Fill PAssword")
    }
    else{
      const data = {
          "username" : username,
          "password" : password
      }
      axios.post(`${baseurl}/User/Login/`,data)
      .then((res) => {
        if(res.data.Status != 200){
          seterror(res.data.Message)
        }
        else{
          localStorage.setItem('usertoken',res.data.token);
          localStorage.setItem('userid',res.data.user_id);
          return navigate("/");
        }
      })
   
    }
  
  
  }

  return (
    <>
    <div className="row">
      <div className="col-lg-4"></div>
    
    <div className="login-box col-lg-4" style={{"paddingRight":40,"paddingLeft":40,"paddingTop":40}} >
      <div className="login-logo">
        <a href="../../index2.html">
          <b>Jetronics</b>
        </a>
      </div>
      {/* /.login-logo */}
      <div className="card">
        <div className="card-body login-card-body">
          <p className="login-box-msg">Sign in to start your session</p>
          {error ? <p className="login-box-msg" style={{"color":"red"}}>{error}</p>:"" }
          <form>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Username"
                value={username}
                onChange={(e)=>setusername(e.target.value)}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-envelope" />
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                value={password}
                onChange={(e)=>setpassword(e.target.value)}
              />
             
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock" />
                </div>
              </div>
              
            </div>
           
            <div className="row">
              <div className="col-8">
                
              </div>
              {/* /.col */}
              <div className="col-4">
                <button type="submit" className="btn btn-primary btn-block" onClick={(e)=>Login(e)}>
                  Sign In
                </button>
              </div>
              {/* /.col */}
            </div>
          </form>
        
          <p className="mb-1 mt-4" style={{"paddingLeft":180}}>
            <a href="forgot-password.html" >I forgot my password</a>
          </p>
        
        </div>
        {/* /.login-card-body */}
      </div>
    </div>

    <div className="col-lg-4"></div>
    </div>
    </>
  );
}

export default Loginpage;
