import './App.css';
import AllRoute from './Route/AllRoute';

function App() {
  return (
    <div>
     <AllRoute/>
    </div>
  );
}

export default App;
