import React from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Loginpage from '../AdminPages/Loginpage'
import Dashboard from '../AdminPages/Dashboard';
import Products from '../AdminPages/Products';
import Categorypage from '../AdminPages/Categorypage';
import Statuspage from '../AdminPages/Statuspage';
import CityPage from '../AdminPages/CityPage';
import OrderPage from '../AdminPages/OrderPage';
import OrderDetailsShow from '../AdminPages/OrderDetailsShow';
import MissingOrders from '../AdminPages/MissingOrders';
import ProfilePage from '../AdminPages/ProfilePage';


function AllRoute() {
  return (
    <div>
        <Router>
           <Routes>

                <Route path="/login" element={<Loginpage />} />
                <Route path="/" element={<Dashboard />} />
                <Route path="/categorylist" element={<Categorypage />} />
                <Route path="/productlist" element={<Products />} />
                <Route path="/status" element={<Statuspage />} />
                <Route path="/cities" element={<CityPage />} />
                <Route path="/orders" element={<OrderPage />} />
                <Route path="/shop/:id/" element={<OrderDetailsShow />} />
                <Route path="/missingorder" element={<MissingOrders />} />
                <Route path="/profile" element={<ProfilePage />} />

              
            </Routes>
           </Router>
    </div>
  )
}

export default AllRoute