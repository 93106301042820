import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { baseurl } from '../Contants/Constanturls'
import Header from '../DashboardComponents/Header'
import Sidebar from '../DashboardComponents/Sidebar'
import { Button, Modal } from "react-bootstrap";
import { CSVLink } from 'react-csv'



function MissingOrders() {

  const [allmissingorder, setallmissingorder] = useState([])

  const [allcity, setallcity] = useState([])
  const [allproduct, setallproduct] = useState([])

  const [modal, setmodal] = useState(false)

  // const [clientname, setclientname] = useState("")
  // const [clientphone, setclientphone] = useState("")
  // const [clientaddress, setclientaddress] = useState("")
  // const [productid, setproductid] = useState("")
  // const [productqty, setproductqty] = useState("")

  const [arrays, setarrays] = useState([]);
  const [report, setreport] = useState([])



  const [prsizes, setprsizes] = useState("")
  const [prcolours, setprcolours] = useState("")

  const [orderdata, setorderdata] = useState({
    'clientname' : "",
    "phone" : "",
    'userrandomid':"" ,
    'city': "" ,
    'quantity': "" ,
    'address': "",
    'product_code':"" ,
    'product_name':"" ,
    'product_price':"" ,
    'deliverycharge': "" ,
    'product_VAT': "",
    'product_total': "",
    'product_size': "",
    'product_colour': "",
    'product_image_url' : "",
    'product_id' : "",
  })

  useEffect(() => {
    MissingOrder(null)
    Allcity()
    Allproduct()
  }, [])

  const MissingOrder = (id)  => {
    if (id == null) {
      axios.get(`${baseurl}/OrderApp/missingorder/`)
      .then((res)=>{
        setallmissingorder(res.data)
      })
    }
    else{
      axios.get(`${baseurl}/OrderApp/missingorder/`,{
        params:{
          id : id
        }
      })
      .then((res)=>{
        var data = res.data[0] 
        console.log(data)
        setmodal(true)
        setorderdata({
          'clientname' : data.clientname,
          "phone" : data.phone,
          'userrandomid':data.userrandomid ,
          'city': data.city ,
          'quantity': data.quantity ,
          'address': "",
          'product_code':data.product[0].code ,
          'product_name':data.product[0].title ,
          'product_price':`${data.product[0].offer_price == 0 || data.product[0].offer_price == null ? `${data.product[0].price}` : `${data.product[0].offer_price}` }` ,
          'deliverycharge': data.product[0].deliverycharge ,
          'product_VAT': "",
          'product_total': "",
          'product_size': data.size,
          'product_colour': data.colour,
          'product_id' : data.product[0].id,
          'product_image_url' : data.product[0].product_image
        })

        if (data.product[0].colour != ""){
          var coloursplit = data.product[0].colour.split(',')
          setprcolours(coloursplit)
       }
       else {
        setprcolours([])
       }
       if (data.product[0].size != "") {
        var sizesplit = data.product[0].size.split(',')
        setprsizes(sizesplit)
     
       }
       else{
        setprsizes([])
       }
      })

    }
   
  }

  const Allcity = () => {
    axios.get(`${baseurl}/StatusApp/cities/`).then((res) => {
      setallcity(res.data);
    });
  }

  const Allproduct = () => {
    axios.get(`${baseurl}/ProductApp/productuser/`).then((res)=>{
      
      setallproduct(res.data)
     
    })
  }

  const ChangeHandler = (e) => {
    setorderdata({ ...orderdata, [e.target.name]: e.target.value });
  };

  const PlaceOrder  = () => {
    var product_total =(orderdata.product_price) * (orderdata.quantity)
    var VAT = (product_total * 0.05)
    var data = {
      'clientname' : orderdata.clientname,
      "phone" : orderdata.phone,
      'userrandomid':orderdata.userrandomid ,
      'city': orderdata.city ,
      'quantity': orderdata.quantity ,
      'address': orderdata.address ,
      'product_code':orderdata.product_code ,
      'product_name':orderdata.product_name ,
      'product_price':orderdata.product_price ,
      'deliverycharge': orderdata.deliverycharge ,
      'product_VAT': VAT,
      'product_total': (Number(product_total) + Number(VAT) + Number(orderdata.deliverycharge)),
      'product_size': orderdata.product_size,
      'product_colour': orderdata.product_colour,
      'product_image_url' : `${baseurl}${orderdata.product_image_url}`,
      'product_id' : orderdata.product_id,
    }

    axios.post(`${baseurl}/OrderApp/allorder/`,data)
    .then((res)=>{
      MissingOrder()
      setmodal(false)
    })
  }
  

  const DeleteOrder = (id)=>{
  
    axios.delete(`${baseurl}/OrderApp/missingorder/`,{
      params : {
        id : id
      }
    })
    .then((res)=>{
      console.log(res.data)
      MissingOrder()
    })
  }

  const headers  = [
    { label : 'Client Name', key : 'clientname'},
    { label : 'Address', key : 'address'},
    { label : 'Contact Number', key : 'phone'},
    { label : 'City', key : 'city'},
    { label : 'Quantity', key : 'quantity'},
    { label : 'Size', key : 'size'},
    { label : 'Colour', key : 'colour'},
    {label : 'Created Date' , key : 'created_date'}
  ];

  
  const csvReport = {
    filename : 'MissingOrderReport.csv',
    headers : headers,
    data : report,
    
  }


  const handleCheckboxChange = (e) => {
  
    let newArray = [...arrays, e.target.id];
    if (arrays.includes(e.target.id)) {
      newArray = newArray.filter(id => id !== e.target.id);
    } 
    setarrays(newArray)
    CSVGenerator(newArray)
   
  };

 

  const CSVGenerator = (datas) => {

    var data= {
      "id" : datas,
    }
    axios.post(`${baseurl}/OrderApp/missingorderreport/`,data)
    .then((res)=>{
      setreport(res.data.data)
      console.log(res.data.data)
      
    })
  }

  const DeleteMultiple = () => {
 
    axios.delete(`${baseurl}/OrderApp/deletemultiplemissingorder/`,{
      data: {
        'ids': arrays
      }
    })
    .then((res)=>{
      MissingOrder(null)
      setarrays([])
    })
  }


  return (
    <div>
        <Header />
        <Sidebar />
        <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="card card-default">
            </div>
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">order List</h1>
              </div>
              <div className="col-sm-6">      
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                <div className="card-header">
                  {arrays.length != 0 ? 
                    <div className='row'>
                      <div className='col-md-3'>
                        <button className='btn' style={{"backgroundColor":"#bf2222"}} onClick={()=>window.location.reload()}><i className='fas fa-download'></i> <CSVLink {...csvReport} >Report</CSVLink> </button>
                        <button className='btn ml-2' style={{"backgroundColor":"#bf2222"}} onClick={DeleteMultiple} ><i className='fas fa-delete'></i> Delete </button>
                      </div>
                    </div>
                     : '' }
                  
                  </div>

                  <div className="card-body">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th style={{ width: 20 }}>#</th>                    
                          <th style={{ width: 200 }}>Product</th>
                          <th style={{ width: 100 }}>Client Name</th>
                          <th style={{ width: 40 }}>Phone</th> 
                          <th style={{ width: 40 }}>City</th> 
                          <th style={{ width: 40 }}>Created date</th> 
                          <th style={{ width: 40 }}>Action</th> 
                        </tr>
                      </thead>
                      <tbody>
                        {allmissingorder.length == 0 ? 'No Data' : <>
                        {allmissingorder.map((value,key)=>(

                          <tr key={key}>
                                  <td>{key + 1} <br/>
                                  <input type="checkbox"  id={value.id}
                              onChange={(e) => handleCheckboxChange(e)}></input></td>
                                  <td>{value.product[0].title}<br></br>
                                  <img src={`${baseurl}${value.product[0].product_image}`} alt height="100px" width="50px" style={{"objectFit":"contain"}}/></td>
                                  <td>{value.clientname}</td>
                                  <td>{value.phone}</td>
                                  <td>{value.city}</td>
                                  <td>{value.created_date}</td>
                                  <td> <button
                                    className="badge bg-warning mr-1"
                                    style={{ border: "None",borderRadius:"10px"}}
                                    onClick={()=>MissingOrder(value.id)}
                                  
                                  >
                                    Edit To <br/>orders
                                  </button>
                                  <button
                                    className="badge bg-danger mr-1"
                                    style={{ border: "None",borderRadius:"10px"}}
                                    onClick={()=>DeleteOrder(value.id)}
                                  
                                  >
                                    Delete
                                  </button></td>

                          </tr>

                        ))}
                        </>}        
                      </tbody>
                    </table>
                  </div>

               
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal
          show={modal}
          // onHide={true}
          backdrop="static"
          keyboard={false}
         
         
        >
          <Modal.Header>
            <Modal.Title>Missing Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className='row'>
                <div className='col-md-6'>
                  <label>Client Name</label>
                  <input type="text" className='form-control' value={orderdata.clientname} name="clientname" placeholder='Name' onChange={ChangeHandler}></input>
                </div>
                <div className='col-md-6'>
                <label>Client Phone</label>
                  <input type="number" className='form-control' value={orderdata.phone} name="phone" placeholder='Phone' onChange={ChangeHandler}></input>
                </div>
              </div><br/>
              <div className='row'>
                <label>Client Address</label>
                <input type="text" className='form-control' value={orderdata.address} name="address" placeholder='Address' onChange={ChangeHandler}></input>
              </div><br/>
              {/* <div className='row'>
              <label>Product</label>
                <select className='form-control'  name="clientname">
                  <option hidden defaultChecked>-- Choose Product -- </option>
                  {allproduct.map((value,key)=>(
                     <option value={value.id}> {value.title}</option>
                  ))}
                 </select>
              </div><br/> */}
              <div className='row'>
              <div className='col-md-6'>
                <label>City</label>
                 <select className='form-control' value={orderdata.city} name="city" onChange={ChangeHandler}>
                   <option hidden defaultChecked>-- Choose City --</option>
                      {allcity.map((value,key)=>(
                        <option value={value.name}>{value.name}</option>
                      ))}
                 </select>
                </div>
                <div className='col-md-6'>
                  <label>Quantity</label>
                <select className='form-control' value={orderdata.quantity} name="quantity" onChange={ChangeHandler}>
                  <option hidden defaultChecked>-- Choose Quantity -- </option>
                  <option value={1}>1  - { 1 * `${orderdata.product_price}`} AED</option>
                  <option value={2}>2  - { 2 * `${orderdata.product_price}`} AED</option>
                  <option value={3}>3  - { 3 * `${orderdata.product_price}`} AED</option>
                  <option value={4}>4  - { 4 * `${orderdata.product_price}`} AED</option>
                  <option value={5}>5  - { 5 * `${orderdata.product_price}`} AED</option>
                  <option value={6}>6  - { 6 * `${orderdata.product_price}`} AED</option>
                  <option value={7}>7  - { 7 * `${orderdata.product_price}`} AED</option>
                  <option value={8}>8  - { 8 * `${orderdata.product_price}`} AED</option>
                  <option value={9}>9  - { 9 * `${orderdata.product_price}`} AED</option>
                  <option value={10}>10  - { 10 * `${orderdata.product_price}`} AED</option>
                 </select>
                </div>
              </div> <br/>
             
              <div className='row'>
                    <div className='col-md-6'>
                    {prcolours.length == 0 ? '' : <>
                  <label>Colour</label><br/>
                  <select className="form-control" name="product_colour" value={orderdata.product_colour} onChange={ChangeHandler}  >
                    <option hidden defaultChecked> -- Choose Colour --</option>
                    {prcolours.map(value=>
                        <option value={value}>{value}</option>
                      )}
                  </select>
                  </>}<br/>
                    </div>
                    <div className='col-md-6'>
                    {prsizes.length == 0 ? '' : <>
                  <label>Size</label><br/>
                  <select className="form-control" value={orderdata.product_size} name="product_size" onChange={ChangeHandler}  >
                    <option hidden defaultChecked> -- Choose Colour --</option>
                    {prsizes.map(value=>
                        <option value={value}>{value}</option>
                      )}
                  </select>
                  </>}
                    </div>
              </div>
            </form>
         
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>setmodal(false)}   >
                Cancel
              </Button>

              <Button variant="primary" onClick={PlaceOrder}    >
                Save
              </Button>
      
            </Modal.Footer>
          </Modal.Body>
        </Modal>

   
      </div>
    </div>
  )
}

export default MissingOrders