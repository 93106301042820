import React, { useEffect, useState } from "react";
import Header from "../DashboardComponents/Header";
import Sidebar from "../DashboardComponents/Sidebar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseurl } from "../Contants/Constanturls";
import { Button, Modal } from "react-bootstrap";

function ProfilePage() {
  let navigate = useNavigate();

  const [updateprofile, setupdateprofile] = useState({
    username: "",
    gender: "",
    dob: "",
    email: "",
    mobile: "",
    password : ""
  });

  const [modal, setmodal] = useState(false);
  const [admindata, setadmindata] = useState({
    username: "",
    gender: "",
    dob: "",
    email: "",
    mobile: "",
    createddate: "",
    status: "",
    
  });

  useEffect(() => {
    if (!localStorage.getItem("usertoken")) {
      return navigate("/login");
    } else {
      LoginedUser();
    }
  }, []);

  const LoginedUser = () => {
    axios
      .get(`${baseurl}/User/User/`, {
        headers: { Authorization: localStorage.getItem("usertoken") },
      })
      .then((res) => {
        console.log(res.data)
        var data = res.data[0];
        setadmindata({
          username: data.username,
          gender: data.gender,
          dob: data.dob,
          email: data.email,
          mobile: data.mobile,
          createddate: data.created_date,
          status: data.is_active,
         
        });
      });
  };

  const Userdetails = () => {
      setmodal(true)
      setupdateprofile({
        username: admindata.username,
        gender: admindata.gender,
        dob: admindata.dob,
        email: admindata.email,
        mobile: admindata.mobile,
        
      })
  }

  const ChangeHandler = (e) => {
    setupdateprofile({ ...updateprofile, [e.target.name]: e.target.value });
  };


  const ChangeProfile = () => {
    var data = {
        'id':localStorage.getItem('userid'),
        'username' : updateprofile.username,
        'password' : updateprofile.password,
        "gender": updateprofile.gender,
        "dob": updateprofile.dob,
        "email": updateprofile.email,
        "mobile": updateprofile.mobile,
    }
    axios.post(`${baseurl}/User/UserView/`,data,{
        headers: { Authorization: localStorage.getItem("usertoken") },
    }).then((res)=>{
        LoginedUser()
        setmodal(false)
    })
        
  };
  
  return (
    <div>
      <Header />
      <Sidebar />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            {/* /.card */}
            <div className="row mb-2"></div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div className="col-md-3">Profile</div>
                      <div className="col-md-9">
                        <center>
                          <label>About</label>
                        </center>
                      </div>
                      {/* <div className='col-md-3'></div>
                          <div className='col-md-3'></div> */}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <img
                          src="dist/img/avatar5.png"
                          alt="AdminLTE Logo"
                          className="brand-image img-circle elevation-3"
                          style={{ opacity: ".8" }}
                        />
                        <br></br>
                        <button
                          className="btn btn-success mt-4"
                          style={{ marginLeft: "30px" }}
                          onClick={() => Userdetails()}
                        >
                          {" "}
                          Update Profile
                        </button>
                      </div>
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-md-4">
                            <div style={{ float: "left" }}>
                              <p
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "15px",
                                }}
                              >
                                Username :
                              </p>
                              <br />
                              <p
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "15px",
                                }}
                              >
                                Gender :
                              </p>
                              <br />
                              <p
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "15px",
                                }}
                              >
                                DOB :
                              </p>
                              <br />
                              <p
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "15px",
                                }}
                              >
                                Email :
                              </p>
                              <br />
                              <p
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "15px",
                                }}
                              >
                                Phone :
                              </p>
                              <br />
                              <p
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "15px",
                                }}
                              >
                                Created date :
                              </p>
                              <br />
                              <p
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "15px",
                                }}
                              >
                                Status :
                              </p>
                              <br />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div style={{ float: "left" }}>
                              <p>
                                {admindata.username == null
                                  ? "Not Registered"
                                  : `${admindata.username}`}
                              </p>
                              <br />
                              <p>
                                {admindata.gender == null
                                  ? "Not Registered"
                                  : `${admindata.gender}`}
                              </p>
                              <br />
                              <p>
                                {admindata.dob == null
                                  ? "Not Registered"
                                  : `${admindata.dob}`}
                              </p>
                              <br />
                              <p>
                                {admindata.email == null
                                  ? "Not Registered"
                                  : `${admindata.email}`}
                              </p>
                              <br />
                              <p>
                                {admindata.mobile == null
                                  ? "Not Registered"
                                  : `${admindata.mobile}`}
                              </p>
                              <br />
                              <p>
                                {admindata.createddate == "Not Registered"
                                  ? ""
                                  : `${admindata.createddate}`}
                              </p>
                              <br />
                              <p>
                                {admindata.status == true ? (
                                  <>
                                    <button className="btn btn-success">
                                      Active
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button className="btn btn-danger">
                                      In Active
                                    </button>
                                  </>
                                )}
                              </p>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          show={modal}
          // onHide={true}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Update Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form autoComplete="off">
              <div className="row mb-4">
                <div className="col-md-6">
                  {" "}
                  <label>Username : *</label>
                  <input type="text" className="form-control" name="username" value={updateprofile.username} onChange={ChangeHandler} />
                </div>
                <div className="col-md-6">
                  <label>Password:</label>
                  <input type="text" className="form-control" name="password" value={updateprofile.password} onChange={ChangeHandler} />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-6">
                  {" "}
                  <label>Gender : *</label>
                  <input type="text" className="form-control" name="gender" value={updateprofile.gender} onChange={ChangeHandler} />
                </div>
                <div className="col-md-6">
                  <label>DOB:</label>
                  <input type="text" className="form-control" name="dob" value={updateprofile.dob} onChange={ChangeHandler} />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-6">
                  {" "}
                  <label>Phone : *</label>
                  <input type="text" className="form-control" name="mobile" value={updateprofile.mobile} onChange={ChangeHandler}/>
                </div>
                <div className="col-md-6">
                  <label>Email:</label>
                  <input type="text" className="form-control" name="email" value={updateprofile.email} onChange={ChangeHandler} />
                </div>
              </div>
            </form>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setmodal(false)}>
                Cancel
              </Button>
              {/* <Button variant="primary" onClick={()=>{addcategory();handleClose()}}> */}
              <Button variant="primary" onClick={ChangeProfile}>
                Save
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default ProfilePage;
