import React, { useEffect, useState } from "react";
import Header from "../DashboardComponents/Header";
import Sidebar from "../DashboardComponents/Sidebar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseurl } from "../Contants/Constanturls";
import { Button, Modal } from "react-bootstrap";

function Categorypage() {
  let navigate = useNavigate();

  const [modal, setmodal] = useState(false);
  const [category, setcategory] = useState([]);
  const [categorypg, setcategorypg] = useState([]);

  const [categoryname, setcategoryname] = useState("");
  const [categorydescription, setcategorydescription] = useState("");
  const [categoryid, setcategoryid] = useState("");

  const [searchname, setsearchname] = useState("");

  const [prevpgcount, setprevpgcount] = useState(0)
  const [pgcount, setpgcount] = useState(0)

  useEffect(() => {
    CategoryDatas();
    
  }, []);

  const Datas = (id) => {
    if (id == null) {
      setcategoryname("");
      setcategorydescription("");
      setcategoryid("");
      setmodal(true);
    } else {
      axios
        .get(`${baseurl}/ProductApp/categoryall/`, {
          params: {
            id: id,
          },
        })
        .then((res) => {
          setcategoryname(res.data[0].name);
          setcategorydescription(res.data[0].description);
          setcategoryid(res.data[0].id);
          setmodal(true);
        });
    }
  };

  const Searchcategory = (e) => {
    e.preventDefault();
    if (searchname == "") {
      alert("PLease Fill Search Field");
    } else {
      axios
        .get(`${baseurl}/ProductApp/category/`, {
          headers: { Authorization: localStorage.getItem("usertoken") },
          params: {
            name: searchname,
          },
        })
        .then((res) => {
          setcategory(res.data.results);
          setcategorypg(res.data);
        });
    }
  };

  const Addcategory = (e) => {
    e.preventDefault();

    if (categoryname == "") {
      alert("Please Fill Category Name");
    } else {
      const data = {
        name: categoryname,
        description: categorydescription,
        id: categoryid,
      };
      axios
        .post(`${baseurl}/ProductApp/category/`, data, {
          headers: { Authorization: localStorage.getItem("usertoken") },
        })
        .then((res) => {
          setcategoryname("");
          setcategorydescription("");
          setmodal(false);
          CategoryDatas();
        });
    }
  };

  const CategoryDatas = () => {
    if (!localStorage.getItem("usertoken")) {
      return navigate("/login");
    } else {
      axios
        .get(`${baseurl}/ProductApp/category/`, {
          headers: { Authorization: localStorage.getItem("usertoken") },
        })
        .then((res) => {
          setcategory(res.data.results);
          setcategorypg(res.data);
          if (prevpgcount == 0) {
            setprevpgcount(res.data.results.length)
          }
        });
    }
  };

  const paginationPrev = () => {
    axios
      .get(`${categorypg.previous}`, {
        headers: { Authorization: localStorage.getItem("usertoken") },
      })
      .then((res) => {
        setcategory(res.data.results);
        setcategorypg(res.data);
        setpgcount(Number(pgcount) - Number(prevpgcount))
      });
  };

  const paginationNext = () => {
    axios
      .get(`${categorypg.next}`, {
        headers: { Authorization: localStorage.getItem("usertoken") },
      })
      .then((res) => {
        setcategory(res.data.results);
        setcategorypg(res.data);
        setpgcount(Number(pgcount) + Number(prevpgcount))
      });
  };

  const DeleteCategory = (id) => {
    const data = {
      id: id,
    };
    axios
      .delete(`${baseurl}/ProductApp/category/`, {
        headers: { Authorization: localStorage.getItem("usertoken") },
        data,
      })
      .then((res) => {
        CategoryDatas();
      });
  };

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-header">
                <h3 className="card-title">Search</h3>
                <div className="card-tools"></div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        className="form-control select2"
                        value={searchname}
                        onChange={(e) => {
                          setsearchname(e.target.value);
                        }}
                        placeholder="Category Name"
                        style={{ width: "70%" }}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-6">
                        <button
                          type="button"
                          style={{ float: "right", width: "100%" }}
                          onClick={(e) => Searchcategory(e)}
                          class="btn btn-primary btn-block"
                        >
                          Search
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button
                          type="button"
                          style={{ float: "right", width: "100%" }}
                          onClick={(e) => {
                            CategoryDatas();
                            setsearchname("");
                          }}
                          class="btn btn-primary btn-block"
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}

            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Category List</h1>
              </div>

              <div className="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <button
                    type="button"
                    class="btn btn-primary btn-block"
                    onClick={() => Datas(null)}
                  >
                    <i class="fa fa-plus"></i> Add Category
                  </button>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header"></div>

                  <div className="card-body">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th style={{ width: 20 }}>#</th>
                          <th style={{ width: 250 }}>Name</th>
                          <th style={{ width: 250 }}>Description</th>
                          <th style={{ width: 100 }}>Created Date</th>
                          <th style={{ width: 40 }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {category.count === 0 ? (
                          "No Category Found"
                        ) : (
                          <>
                            {category.map((value, key) => (
                              <tr key={key}>
                                <td> {categorypg.previous == null ? `${key+1}` : `${pgcount + key + 1}`}</td>
                                <td>{value.name}</td>
                                <td>{value.description}</td>
                                <td>
                                  <span>{value.created_date}</span>
                                </td>
                                <td>
                                  <button
                                    className="badge bg-warning mr-1"
                                    style={{ border: "None" }}
                                    onClick={() => Datas(value.id)}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    className="badge bg-danger"
                                    onClick={() => {
                                      window.confirm(
                                        "Do You Want To delete   " + value.name
                                      )
                                        ? DeleteCategory(value.id)
                                        : window.close();
                                    }}
                                    style={{ border: "None" }}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="card-footer clearfix">
                    <ul className="pagination pagination-sm m-0 float-right">
                      <li className="page-item" onClick={paginationPrev}>
                        <p className="page-link" style={ categorypg.previous == null ? {cursor : "not-allowed"} : {cursor : "pointer"}}>
                          Previous
                        </p>
                      </li>
                      {/* <li className="page-item">
                        <a className="page-link" href="#">
                          1
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          3
                        </a>
                      </li> */}
                      <li className="page-item" onClick={paginationNext}>
                        <p className="page-link" style={ categorypg.next == null ? {cursor : "not-allowed"} : {cursor : "pointer"}}>
                          Next
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          show={modal}
          // onHide={true}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Add/ Edit Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form autoComplete="off">
              <label>Category Name : *</label>
              <input
                type="text"
                className="form-control"
                name="categoryname"
                value={categoryname}
                onChange={(e) => setcategoryname(e.target.value)}
              />
              <label>Category Description:</label>
              <textarea
                className="form-control"
                name="categorydesc"
                value={categorydescription}
                onChange={(e) => setcategorydescription(e.target.value)}
              />

              {/* <Button variant="primary">
                Add Photo
              </Button> */}
            </form>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setmodal(false)}>
                Cancel
              </Button>
              {/* <Button variant="primary" onClick={()=>{addcategory();handleClose()}}> */}
              <Button variant="primary" onClick={(e) => Addcategory(e)}>
                Save
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default Categorypage;
