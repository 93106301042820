import React, { useEffect, useState } from 'react'
import Header from '../DashboardComponents/Header'
import Sidebar from '../DashboardComponents/Sidebar'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { baseurl } from '../Contants/Constanturls';


function Dashboard() {
  let navigate = useNavigate();

  const [todayscount, settodayscount] = useState("")

  const [todaysnewcount, settodaysnewcount] = useState("")

  const [totalorders, settotalorders] = useState("")

  const [missingordertoday, setmissingordertoday] = useState("")
  
  useEffect(() => {
      Dashboarddatas()
  }, [])

  const Dashboarddatas = () => {
    if (!localStorage.getItem('usertoken')){
        return navigate("/login");
    }
    else{
      axios.get(`${baseurl}/ProductApp/details/`)
      .then((res)=>{
        settodayscount(res.data.todaysdata)
        settodaysnewcount(res.data.todaysnewdata)
        settotalorders(res.data.totalorders)
        setmissingordertoday(res.data.missingorder_today)
      })
    }
  }
  
  return (
   
    <div>
      
      <Header/>
      <Sidebar/>
  
  <div className="content-wrapper">
   
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Dashboard</h1>
          </div>
          <div className="col-sm-6">
           
          </div>
        </div>
      </div>
    </div>
  
    
    <section className="content">
      <div className="container-fluid">

        <div className="row">
          <div className="col-lg-3 col-6">
          
            <div className="small-box bg-info">
              <div className="inner">
                <h3>{todayscount}</h3>
                <p>New Orders Today</p>
              </div>
              <div className="icon">
                <i className="ion ion-bag" />
              </div>
              <a className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
        
          <div className="col-lg-3 col-6">
            <div className="small-box bg-success">
              <div className="inner">
                <h3>{todaysnewcount}</h3>
                <p>New Data Today</p>
              </div>
              <div className="icon">
                <i className="ion ion-stats-bars" />
              </div>
              <a className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-warning">
              <div className="inner">
                <h3>{totalorders}</h3>
                <p>Total Orders</p>
              </div>
              <div className="icon">
                <i className="ion ion-person-add" />
              </div>
              <a className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-danger">
              <div className="inner">
                <h3>{missingordertoday == null || missingordertoday == 0 ? '0' : {missingordertoday}}</h3>
                <p>Todays Missing Order</p>
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph" />
              </div>
              <a className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
        </div>
       
      </div>
    </section>
  
  </div>
  
    </div>
  )
}

export default Dashboard