import axios from 'axios'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { baseurl } from '../Contants/Constanturls'
import { Link } from 'react-router-dom'


function Header() {
  let navigate = useNavigate()

  const Logout = () => {
    if (!localStorage.getItem('usertoken')){
      return null
    }
    else{
      localStorage.clear()
      return navigate('/login')
    }
    // axios.get(`${baseurl}/User/Logout/`, {
    //   headers: { Authorization: localStorage.getItem("usertoken") },
    //   params: {
    //     id: localStorage.getItem('userid'),
    //   },
    // }).then((res)=>{
    //     if (res.data.Status == true ) {
    //       localStorage.removeItem('usertoken')
    //       localStorage.removeItem('userid')
    //       return navigate('/login')
    //     }
    // })
  }
  return (
   <div>
  {/* Navbar */}
  <nav className="main-header navbar navbar-expand navbar-white navbar-light">
    {/* Left navbar links */}
    <ul className="navbar-nav">
      <li className="nav-item">
        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
      </li>
    
     
    </ul>
    {/* Right navbar links */}
    <ul className="navbar-nav ml-auto">
      {/* Navbar Search */}
      <li className="nav-item">
        <a className="nav-link" data-widget="navbar-search" href="#" role="button">
          <i className="fas fa-search" />
        </a>
        <div className="navbar-search-block">
          <form className="form-inline">
            <div className="input-group input-group-sm">
              <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
              <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                  <i className="fas fa-search" />
                </button>
                <button className="btn btn-navbar" type="button" data-widget="navbar-search">
                  <i className="fas fa-times" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </li>
      {/* Messages Dropdown Menu */}
      <Link to='/profile'>
      <li className="nav-item">
        <a className="nav-link" data-toggle="dropdown" href="#">
          <button className='btn btn-warning' style={{"marginBottom":"5px"}}><i className="fas fa-user mr-2" />Profile</button>
        </a>
     
      </li>
      </Link>
      <li className="nav-item">
        <a className="nav-link" data-toggle="dropdown" href="#">
        <button className='btn btn-danger' style={{"marginBottom":"5px"}} onClick={Logout}><i className="fas fa-power-off mr-2" />Logout</button>
        </a>
     
      </li>
  

    </ul>
  </nav>
  {/* /.navbar */}
</div>

  )
}

export default Header