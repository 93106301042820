import React, { useEffect, useState } from 'react'
import Header from '../DashboardComponents/Header'
import Sidebar from '../DashboardComponents/Sidebar'
import { useNavigate } from "react-router-dom";
import { baseurl } from '../Contants/Constanturls';
import axios from 'axios';
import { Button, Modal } from "react-bootstrap";



function CityPage() {
    let navigate = useNavigate();

    const [allcities, setallcities] = useState([])

    const [cityname, setcityname] = useState("")
    const [cityid, setcityid] = useState("")

    const [citymodal, setcitymodal] = useState(false)

    const [searchcityname, setsearchcityname] = useState("")


    useEffect(() => {
        AllCity()
    }, [])
    

    const AllCity = () => {
       if (!localStorage.getItem('usertoken')){
           return navigate('/login')
       }
       else{
           axios.get(`${baseurl}/StatusApp/cities/`)
           .then((res)=>{
                setallcities(res.data)
           })
       }
    }

    const CityData = (id) => {

        if (id == null) {
             setcityid("")
             setcityname("")
             setcitymodal(true)
        }
        else{
            axios.get(`${baseurl}/StatusApp/cities/`,{
                params : {
                    id : id
                }
            })
            .then((res)=>{
                setcityid(res.data[0].id)
                setcityname(res.data[0].name)
                setcitymodal(true)
            })
        }
    }

    const SubmitCity = () => {
        if (cityname == ""){
            alert("Please Fill The City Name")
        }
        else{
            var data = {
                'name' : cityname,
                'id' : cityid
            }
            axios.post(`${baseurl}/StatusApp/cities/`,data)
            .then((res) => {
                AllCity()
                setcitymodal(false)
            })
        }
    }

    const DeleteCity = (id) => {
        axios
          .delete(`${baseurl}/StatusApp/cities/`, {
            params: {
              id: id,
            },
          })
          .then((res) => {
            AllCity()
          });
      }

    const Searchcity = ()=>{
        if (searchcityname == "") {
            alert("Please Fill City Name")
        }
        else {
            axios.get(`${baseurl}/StatusApp/cities/`,{
                params:{
                    name:searchcityname
                }
            })
            .then((res)=>{
                setallcities(res.data)
            })
        }
    }
    
  return (
    <div>
        <Header/>
        <Sidebar/>
        
  <div className="content-wrapper">
   
  <div className="content-header">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-header">
                <h3 className="card-title">Search</h3>
                <div className="card-tools"></div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        className="form-control select2"
                        value={searchcityname}
                        onChange={(e)=>setsearchcityname(e.target.value)}
                        placeholder="City Name"
                        style={{ width: "70%" }}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-6">
                        <button
                          type="button"
                          style={{ float: "right", width: "100%" }}
                          onClick={()=>Searchcity()}
                          class="btn btn-primary btn-block"
                        >
                          Search
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button
                          type="button"
                          style={{ float: "right", width: "100%" }}
                          onClick={()=>{AllCity();setsearchcityname("")}}
                          class="btn btn-primary btn-block"
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}

            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">City List</h1>
              </div>

              <div className="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <button
                    type="button"
                    class="btn btn-primary btn-block"
                    onClick={()=>CityData(null)}
                   
                  >
                    <i class="fa fa-plus"></i> Add City
                  </button>
                </ol>
              </div>
            </div>
          </div>
        </div>
 
   
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header"></div>

                  <div className="card-body">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th style={{ width: 20 }}>#</th>
                          <th style={{ width: 250 }}>Name</th>
                          <th style={{ width: 100 }}>Created Date</th>
                          <th style={{ width: 40 }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                            {allcities.length == 0 ? 'No Data Found' : <>

                           {allcities.map((value,key)=>(
                                     <tr key={key}>
                                     <td> {key+1}</td>
                                     <td>{value.name}</td>
                                     <td>{value.created_date}</td>
                                     <td>
                                       <button
                                         className="badge bg-warning mr-1"
                                         style={{ border: "None" }}
                                         onClick={()=>CityData(value.id)}
                                       
                                       >
                                         Edit
                                       </button>
                                       <button
                                         className="badge bg-danger"
                                         onClick={() => {
                                           window.confirm(
                                             "Do You Want To delete   " + value.name
                                           )
                                             ? DeleteCity(value.id)
                                             : window.close();
                                         }}
                                         style={{ border: "None" }}
                                       >
                                         Delete
                                       </button>
                                     </td>
                                   </tr>
                           ))}
                        

                              </>
                              }
                        
                    
                      </tbody>
                    </table>
                  </div>

              
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          show={citymodal}
          // onHide={true}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Add/ Edit Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form autoComplete="off">
              <label>City Name : *</label>
              <input
                type="text"
                className="form-control"
                name="cityname"
                value={cityname}
                onChange={(e)=>setcityname(e.target.value)}
              />     
                      
            </form>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>setcitymodal(false)} >
                Cancel
              </Button>
              {/* <Button variant="primary" onClick={()=>{addcategory();handleClose()}}> */}
              <Button variant="primary"  onClick={()=>SubmitCity()} >
                Save
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
 
 </div>
    </div>
  )
}

export default CityPage