import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import Header from "../DashboardComponents/Header";
import Sidebar from "../DashboardComponents/Sidebar";
import { Button, Modal } from "react-bootstrap";
import axios from 'axios';
import { baseurl } from '../Contants/Constanturls';


function Statuspage() {
    let navigate = useNavigate();


    const [allstatus, setallstatus] = useState([])
    const [statusmodal, setstatusmodal] = useState(false)


    const [statusname, setstatusname] = useState("")         
    const [statuscode, setstatuscode] = useState("")         
    const [statusdescription, setstatusdescription] = useState("")
    const [statuscolor, setstatuscolor] = useState("")
    const [statusid, setstatusid] = useState("")
    
    useEffect(() => {
        StatusPageData()
    }, [])
  
    const StatusPageData = () => {
      if (!localStorage.getItem('usertoken')){
          return navigate("/login");
      }
      else{
        axios.get(`${baseurl}/StatusApp/status/`)
        .then((res)=>{
          setallstatus(res.data)
        })
      }
    }

    const StatusData = (id) => {
      if (id==null) {
        setstatuscolor("")
        setstatusdescription("")
        setstatusname("")
        setstatusid("")
        setstatuscode("")
        setstatusmodal(true)
      }
      else{
        axios.get(`${baseurl}/StatusApp/status/`, {
          params: {
            id: id,
          },
        })
        .then((res)=>{
          setstatusname(res.data[0].name)
          setstatusdescription(res.data[0].description)
          setstatuscolor(res.data[0].colour)
          setstatusid(res.data[0].id)
          setstatuscode(res.data[0].statuscode)
          setstatusmodal(true)
        })
      }
    }

    const SubmitStatus = () => {
      if (statusname == ""){
        alert("Please Fill Statusname")
      }
      else if (statuscolor == ""){
        alert("Please Fill Status Color")
      }
      else{
        var data = {
          "name" : statusname,
          "description" : statusdescription,
          "colour" : statuscolor,
          "statuscode" : statuscode,
          "id" : statusid
        }
        axios.post(`${baseurl}/StatusApp/status/`,data)
        .then((res)=>{
          setstatusmodal(false)
          StatusPageData()
        })
      }
    }

    const DeleteStatus = (id) => {
      axios
        .delete(`${baseurl}/StatusApp/status/`, {
          params: {
            id: id,
          },
        })
        .then((res) => {
          StatusPageData()
        });
    }
  return (
    <div>
      <Header />
      <Sidebar />

      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Status List</h1>
              </div>

              <div className="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <button type="button" class="btn btn-primary btn-block" onClick={() => StatusData(null)}>
                    <i class="fa fa-plus"></i> Add Status
                  </button>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                      {/* <div className='row'>
                      <div className="col-md-6">
                      
                        </div>
                        <div className="col-md-6">
                
              </div>
                      </div> */}
                  </div>

                  <div className="card-body">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th style={{ width: 20 }}>#</th>
                          <th style={{ width: 200 }}>Name</th>
                          <th style={{ width: 100 }}>Status Code</th>
                          <th style={{ width: 200 }}>Description</th>
                          <th style={{ width: 100 }}>Created Date</th>
                          <th style={{ width: 40 }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allstatus.length == 0 ? <tr><center>No Data Found</center></tr>:
                        <>                        
                        {allstatus.map((value,key)=>(
                          <tr key={key}>
                            <td>{key+1}</td>
                            <td> <span
                                    className="badge"                      
                                    style={{ border: "None",backgroundColor:`${value.colour}` , width:"50%",height:"20px","color":"white" }}>
                                    {value.name}
                                  </span></td>
                            <td>{value.statuscode}</td>
                            <td>{value.description}</td>
                            <td>{value.created_date}</td>
                            <td>
                              {value.statuscode == 5 || value.statuscode == 1 || value.statuscode == 10 ? 'No Action Available' : 
                              <>
                            <button
                                    className="badge bg-warning mr-1"
                                    style={{ border: "None" }}
                                    onClick={()=>StatusData(value.id)}     
                                  >
                                    Edit
                                  </button>
                                  <button
                                    className="badge bg-danger"             
                                    style={{ border: "None" }}
                                    onClick={()=>DeleteStatus(value.id)}
                                  >
                                    Delete
                                  </button>
                                  </>
                                  }
                            </td>
                          </tr>
                        ))}
                        </>

                        }
                      </tbody>
                    </table>
                  </div>

                  {/* <div className="card-footer clearfix">
                    <ul className="pagination pagination-sm m-0 float-right">
                      <li className="page-item">
                        <p className="page-link" style={{ cursor: "pointer" }}>
                          Previous
                        </p>
                      </li>

                      <li className="page-item">
                        <p className="page-link" style={{ cursor: "pointer" }}>
                          Next
                        </p>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          show={statusmodal}
          // onHide={true}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Add/ Edit Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form autoComplete="off">
              <label>Status Name : *</label>
              <input
                type="text"
                className="form-control"
                name="statusname"
                value={statusname}
                onChange={(e)=>setstatusname(e.target.value)}
              />     
              <label>Status Code : *</label>
              <input
                type="text"
                className="form-control"
                name="statusname"
                value={statuscode}
              
                onChange={(e)=>setstatuscode(e.target.value)}
              />     
              <label>Status Description:</label>
              <textarea
                type="text"
                className="form-control"
                name="description"
                value={statusdescription}
                onChange={(e)=>setstatusdescription(e.target.value)}
              />     
              <label>Status Colour :</label><br/>
                  <input type="Color" 
                  name="statuscolor" 
                  className='form-group form-control-color'
                  value={statuscolor} 
                  onChange={(e)=>setstatuscolor(e.target.value)}/>
               
             

             
            </form>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>setstatusmodal(false)} >
                Cancel
              </Button>
              {/* <Button variant="primary" onClick={()=>{addcategory();handleClose()}}> */}
              <Button variant="primary" onClick={SubmitStatus}>
                Save
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>



      </div>
    </div>
  );
}

export default Statuspage;
